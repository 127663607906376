import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Select, MenuItem } from '@mui/material';
import LineChartComponent from './components/LineChartComponent.tsx';
import PieChartComponent from './components/PieChartComponent.tsx';
import DataTable from './components/DataTable.tsx';
import ReferrerTable from './components/ReferrerTable.tsx'; // Import the new component
import {
  filterDataByTimeRange,
  prepareChartData,
  prepareDeviceDistributionData,
  prepareScreenResolutionData,
  prepareLanguageData,
  prepareOSVersionData,
} from './utils/dataHelpers.ts';
import styles from './CombinedAnalytics.module.scss';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const CombinedAnalytics: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [fingerprintData, setFingerprintData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState('24h');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [analyticsRes, fingerprintRes] = await Promise.all([
          axios.get(`${API_URL}/api/analytics`, { params: { limit: 10000 } }), // Request more records
          axios.get(`${API_URL}/api/fingerprints`, { params: { limit: 10000 } }),
        ]);
        console.log('Analytics API Response:', analyticsRes);
        console.log('Fingerprint API Response:', fingerprintRes);
        setAnalyticsData(analyticsRes.data);
        setFingerprintData(fingerprintRes.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  
  

  const filteredAnalyticsData = filterDataByTimeRange(analyticsData, timeRange);
  const filteredFingerprintData = filterDataByTimeRange(fingerprintData, timeRange);

  const chartData = prepareChartData(filteredAnalyticsData, filteredFingerprintData);
  const deviceDistributionData = prepareDeviceDistributionData(filteredFingerprintData);
  const screenResolutionData = prepareScreenResolutionData(filteredFingerprintData);
  const languageData = prepareLanguageData(filteredFingerprintData);
  const osVersionData = prepareOSVersionData(filteredFingerprintData);

  const urlReferrerData = filteredAnalyticsData.map(({ url, referrer, timestamp }) => ({
    url: url || 'N/A',
    referrer: referrer || 'Direct',
    timestamp: timestamp || new Date().toISOString(), // Use the current time if missing
  }));
  

  return (
    <Box className={styles.container}>
      <Typography variant="h4">Combined Analytics Dashboard</Typography>
      {loading ? (
        <Box sx={{ textAlign: 'center', margin: 2 }}>
          <CircularProgress />
          <Typography variant="body1">Loading analytics data...</Typography>
        </Box>
      ) : (
        <>
          <Box className={styles.timeRangeSelector}>
            <Typography variant="h6">Select Time Range:</Typography>
            <Select
              value={timeRange}
              onChange={(e) => setTimeRange(e.target.value)}
              className={styles.selectBox}
            >
              <MenuItem value="1h">1 Hour</MenuItem>
              <MenuItem value="6h">6 Hours</MenuItem>
              <MenuItem value="24h">24 Hours</MenuItem>
              <MenuItem value="5d">5 Days</MenuItem>
              <MenuItem value="30d">30 Days</MenuItem>
            </Select>
          </Box>

          <LineChartComponent data={chartData} />
          <PieChartComponent title="Device Distribution" data={deviceDistributionData} />
          <PieChartComponent title="Screen Resolution Distribution" data={screenResolutionData} />
          <PieChartComponent title="Language Distribution" data={languageData} />
          <PieChartComponent title="Operating System Version Distribution" data={osVersionData} />
          <DataTable data={chartData} />
          <ReferrerTable data={urlReferrerData} /> {/* Add the new table */}
        </>
      )}
    </Box>
  );
};

export default CombinedAnalytics;
