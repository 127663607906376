// ProductImages.jsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './ProductImages.scss';

const ProductImages = ({ images }) => (
  <div className="product-images">
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      navigation
      autoplay={{ delay: 3000 }}
      pagination={{ clickable: true }}
    >
      {images.map((src, idx) => (
        <SwiperSlide key={idx}>
          <div className="carousel-slide">
            <img src={src} alt={`Image ${idx}`} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default ProductImages;
