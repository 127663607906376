import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Button, Spin, message, InputNumber } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ProductDetail.scss';
import ProductImages from './ProductImages.tsx'; // Import the new component

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

interface Nutrition {
  servingSize: string;
  calories: number | null;
  caloriesKj: number | null;
  totalFat: string | null;
  totalFatPercent: string | null;
  saturatedFat: string | null;
  saturatedFatPercent: string | null;
  cholesterol: string | null;
  cholesterolPercent: string | null;
  sodium: string | null;
  sodiumPercent: string | null;
  totalCarbohydrate: string | null;
  totalCarbohydratePercent: string | null;
  protein: string | null;
  proteinPercent: string | null;
  salt: string | null;
  lactose: string | null;
  fiber: string | null;

}

interface Product {
  _id: string;
  name: string;
  price: number;
  discountedPrice?: number;
  isDiscounted?: boolean;
  images?: string[];
  image?: string;
  description: string;
  composition?: string;
  nutrition?: Nutrition;
}

interface ProductDetailProps {
  cart: { _id: number; name: string; price: number; quantity: number }[];
  setCart: React.Dispatch<React.SetStateAction<{ _id: number; name: string; price: number; quantity: number }[]>>;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ cart = [], setCart }) => {
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState<number>(1);

  const formatDescription = (description: string) => {
    return description.split('<br>').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };


  useEffect(() => {
    axios
      .get(`${API_URL}/api/products/${productId}`)
      .then(response => {
        setProduct(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(`There was an error fetching the product!`, error);
        setLoading(false);
      });
  }, [productId]);

  const handleAddToCart = () => {
    if (!product) return;
  
    // Determine the price to add based on discount
    const priceToAdd =
      product.isDiscounted && product.discountedPrice
        ? product.discountedPrice
        : product.price;
  
    // Check if the product is already in the cart
    const existingItem = cart.find((item) => item._id === product._id);
  
    if (existingItem) {
      // If it exists, update the quantity
      setCart(
        cart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + quantity }
            : item
        )
      );
    } else {
      // If not, add a new item to the cart with images
      setCart([
        ...cart,
        {
          _id: product._id,
          name: product.name,
          price: priceToAdd,
          quantity,
          images: product.images, // Include images array
          image: product.image,    // Include single image if available
        },
      ]);
    }
  
    // Display a success message
    message.success(`${product.name} ${t('addedToCart')} (x${quantity})`);
  };
  

  if (loading) {
    return <Spin size="large" />;
  }

  if (!product) {
    return <div>{t('productNotFound')}</div>;
  }

  const images = product.images && product.images.length > 0
    ? product.images
    : product.image
    ? [product.image]
    : ['https://i.ytimg.com/vi/YzabKuRnESo/hqdefault.jpg'];

  // Ensure serving size is valid
  const servingSizeInGrams = product.nutrition && product.nutrition.servingSize
    ? parseFloat(product.nutrition.servingSize)
    : 0;

  const validServingSize = isNaN(servingSizeInGrams) || servingSizeInGrams <= 0 ? 0 : servingSizeInGrams;

  const calculatePerServing = (per100gValue: number | string | null, servingSize: number) => {
    let value = per100gValue ? parseFloat(per100gValue.toString()) : 0;
    if (isNaN(value) || isNaN(servingSize) || servingSize <= 0) {
      return '';
    }
    const result = (value * servingSize) / 100;
    return result.toFixed(2);
  };

  const renderNutritionRow = (nutrient: string, perServing: string, per100g: string) => {
    return perServing && per100g ? (
      <tr>
        <td>{nutrient}</td>
        <td>{perServing}</td>
        <td>{per100g}</td>
      </tr>
    ) : null;
  };

  return (
    <div className="product-detail">
      <h1>{product.name}</h1>

      <div className="product-detail-content">
      <ProductImages images={images} productName={product.name} />

        <div className="product-info">
          <div className="product-description-container">
            <h3>{t('productDescription')}</h3>
            <p className="product-description"> {formatDescription(product.description)}</p>
          </div>
          <div className="product-description-container">
            <h3>{t('composition')}</h3>
            <p className="product-composition">{product.composition}</p>
          </div>

          {product.nutrition && (
            <div className="nutrition-facts">
              <h3>{t('nutritionFacts')}</h3>
              <table className="nutrition-table">
                <thead>
                  <tr>
                    <th>{t('nutrient')}</th>
                    <th>{t('perServing')} ({product.nutrition.servingSize})</th>
                    <th>{t('per 100g')}</th>
                  </tr>
                </thead>
                <tbody>
                  {renderNutritionRow(
                    t('calories'),
                    calculatePerServing(product.nutrition.calories, servingSizeInGrams),
                    product.nutrition.calories ? product.nutrition.calories.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('caloriesKj'),
                    calculatePerServing(product.nutrition.caloriesKj, servingSizeInGrams),
                    product.nutrition.caloriesKj ? product.nutrition.caloriesKj.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('totalFat'),
                    calculatePerServing(product.nutrition.totalFat, servingSizeInGrams),
                    product.nutrition.totalFat ? product.nutrition.totalFat.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('saturatedFat'),
                calculatePerServing(product.nutrition.saturatedFat, servingSizeInGrams),
                    product.nutrition.saturatedFat ? product.nutrition.saturatedFat.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('totalCarbohydrate'),
                    calculatePerServing(product.nutrition.totalCarbohydrate, servingSizeInGrams),
                    product.nutrition.totalCarbohydrate ? product.nutrition.totalCarbohydrate.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('cholesterol'),
                    calculatePerServing(product.nutrition.cholesterol, servingSizeInGrams),
                    product.nutrition.cholesterol ? product.nutrition.cholesterol.toString() : ''
                  )}
                  {/* //TODO: Who knows if i need one 
                  {renderNutritionRow(
                    t('sodium'),
                    calculatePerServing(product.nutrition.sodium, servingSizeInGrams),
                    product.nutrition.sodium ? product.nutrition.sodium.toString() : ''
                  )} */}
                  {renderNutritionRow(
                    t('protein'),
                    calculatePerServing(product.nutrition.protein, servingSizeInGrams),
                    product.nutrition.protein ? product.nutrition.protein.toString() : ''
                  )}
                    {renderNutritionRow(
                    t('salt'),
                    calculatePerServing(product.nutrition.salt, servingSizeInGrams),
                    product.nutrition.salt ? product.nutrition.salt.toString() : ''
                  )}
                                      {renderNutritionRow(
                    t('lactose'),
                    calculatePerServing(product.nutrition.lactose, servingSizeInGrams),
                    product.nutrition.lactose ? product.nutrition.lactose.toString() : ''
                  )}
                                                        {renderNutritionRow(
                    t('fiber'),
                    calculatePerServing(product.nutrition.fiber, servingSizeInGrams),
                    product.nutrition.fiber ? product.nutrition.fiber.toString() : ''
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Add to Cart Section with Price and Quantity */}
          <div className="add-to-cart-section">
            <div className="price-quantity-container">
              {/* Product Price in Euros (€) */}
              <div className="product-price">
                {product.isDiscounted && product.discountedPrice ? (
                  <>
                    <span className="original-price">€{product.price.toFixed(2)}</span>
                    <span className="discounted-price">€{product.discountedPrice.toFixed(2)}</span>
                  </>
                ) : (
                  <span className="standard-price">€{product.price.toFixed(2)}</span>
                )}
              </div>

              {/* Quantity Selector */}
              <div className="quantity-selector">
                <InputNumber min={1} max={100} value={quantity} onChange={setQuantity} />
              </div>
            </div>

            {/* Add to Cart Button */}
            <Button type="primary" onClick={handleAddToCart} className="add-to-cart">
              {t('addToCart')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
