import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Paper } from '@mui/material';

const PaymentCancelPage: React.FC = () => {
  const navigate = useNavigate();

  const handleBackToShop = () => {
    // Navigate back to the shop or home page
    navigate('/');
  };

  return (
    <div className="payment-cancel-page">
      <Paper elevation={3} className="payment-cancel-container">
        <Typography variant="h4" gutterBottom className="payment-cancel-title">
          Payment Canceled
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unfortunately, your payment has been canceled. You can try again or contact support if you need assistance.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleBackToShop}
          className="payment-cancel-button"
        >
          Back to Shop
        </Button>
      </Paper>
    </div>
  );
};

export default PaymentCancelPage;
