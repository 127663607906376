import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Button, Select, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const SpiceBoxManagement: React.FC = () => {
  const [spiceBoxes, setSpiceBoxes] = useState([]);
  const [products, setProducts] = useState([]);
  const [newSpiceBox, setNewSpiceBox] = useState({
    name: '',
    size: 0,
    spices: [],
    price: '',
    description: '',
    shortDescription: '',
    isPromotion: false,
    images: '',
  });
  const [editingBoxId, setEditingBoxId] = useState<string | null>(null);

  const fetchSpiceBoxes = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/spice-boxes`);
      setSpiceBoxes(response.data);
    } catch (error) {
      console.error('Error fetching spice boxes:', error);
      alert('Failed to fetch spice boxes. Please check the server.');
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
      alert('Failed to fetch products. Please check the server.');
    }
  };

  const saveSpiceBox = async () => {
    try {
      if (editingBoxId) {
        await axios.put(`${API_URL}/api/spice-boxes/${editingBoxId}`, newSpiceBox);
        alert('Spice box updated successfully');
      } else {
        await axios.post(`${API_URL}/api/spice-boxes`, newSpiceBox);
        alert('Spice box added successfully');
      }
      resetForm();
      fetchSpiceBoxes();
    } catch (error) {
      console.error('Error saving spice box:', error);
      alert('Failed to save spice box. Please try again.');
    }
  };

  const deleteSpiceBox = async (id: string) => {
    try {
      await axios.delete(`${API_URL}/api/spice-boxes/${id}`);
      alert('Spice box deleted successfully');
      fetchSpiceBoxes();
    } catch (error) {
      console.error('Error deleting spice box:', error);
      alert('Failed to delete spice box. Please try again.');
    }
  };

  const editSpiceBox = (box: any) => {
    setNewSpiceBox({
      ...box,
      images: box.images.join(', '),
    });
    setEditingBoxId(box._id);
  };

  const resetForm = () => {
    setNewSpiceBox({
      name: '',
      size: 0,
      spices: [],
      price: '',
      description: '',
      shortDescription: '',
      isPromotion: false,
      images: '',
    });
    setEditingBoxId(null);
  };

  useEffect(() => {
    fetchSpiceBoxes();
    fetchProducts();
  }, []);

  return (
    <Box>
      <Typography variant="h5">Spice Box Management</Typography>

      <TextField
        label="Name"
        value={newSpiceBox.name}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, name: e.target.value })}
      />
      <TextField
        label="Size"
        type="number"
        value={newSpiceBox.size}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, size: parseInt(e.target.value, 10) })}
      />
      <TextField
        label="Price"
        value={newSpiceBox.price}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, price: e.target.value })}
      />
      <TextField
        label="Description"
        value={newSpiceBox.description}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, description: e.target.value })}
      />
            <TextField
        label="Short Description"
        value={newSpiceBox.shortDescription}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, shortDescription: e.target.value })}
      />
      <TextField
        label="Image URLs (comma-separated)"
        value={newSpiceBox.images}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, images: e.target.value })}
      />
      <Select
        multiple
        value={newSpiceBox.spices}
        onChange={(e) => setNewSpiceBox({ ...newSpiceBox, spices: e.target.value })}
      >
        {products.map((product) => (
          <MenuItem key={product._id} value={product._id}>
            {product.name}
          </MenuItem>
        ))}
      </Select>
      <Button onClick={saveSpiceBox}>
        {editingBoxId ? 'Update Spice Box' : 'Add Spice Box'}
      </Button>
      {editingBoxId && <Button onClick={resetForm}>Cancel</Button>}

      <Typography variant="h6" sx={{ marginTop: 4 }}>
        Existing Spice Boxes
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Size</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Images</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {spiceBoxes.map((box: any) => (
            <TableRow key={box._id}>
              <TableCell>{box.name}</TableCell>
              <TableCell>{box.size}</TableCell>
              <TableCell>{box.price}</TableCell>
              <TableCell>
                {box.images.map((img: string, idx: number) => (
                  <img key={idx} src={img} alt={`Spice Box ${idx}`} style={{ width: '50px', marginRight: '5px' }} />
                ))}
              </TableCell>
              <TableCell>
                <Button onClick={() => editSpiceBox(box)}>Edit</Button>
                <Button color="error" onClick={() => deleteSpiceBox(box._id)}>
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SpiceBoxManagement;
