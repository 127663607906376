import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Badge } from 'antd';
import { ShoppingCartOutlined, GlobalOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Navbar.scss';

const { Header } = Layout;

interface NavbarProps {
  cartCount: number;
}

const Navbar: React.FC<NavbarProps> = ({ cartCount }) => {
  const { t, i18n } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'lt'>('lt'); // Default to Lithuanian

  const changeLanguage = (lng: 'en' | 'lt') => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="en" onClick={() => changeLanguage('en')}>
        {t('EN')}
      </Menu.Item>
      <Menu.Item key="lt" onClick={() => changeLanguage('lt')}>
        {t('LT')}
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (cartCount > 0) {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 500);
    }
  }, [cartCount]);

  return (
    <Layout>
      <Header className="navbar">
        {/* Left-side Links */}
        <div className="left-menu">
          <Link to="/">{t('Products')}</Link>
          <Link to="/about">{t('About Us')}</Link>
          <Link to="/contact">{t('Contacts')}</Link>
        </div>

        {/* Centered Logo */}
        <div className="logo">
          <Link to="/">
            <img
              src="https://i.ibb.co/mqktPFn/Main-Logo-black.png"
              alt="Logo"
              className="logo-img"
            />
          </Link>
        </div>

        {/* Right-side Menu for Desktop */}
        <div className="desktop-menu">
          <Menu theme="dark" mode="horizontal" selectable={false} className="menu">
            <Menu.Item key="cart" className="cart-icon">
              <Link to="/cart">
                <Badge count={cartCount} className={animate ? 'cart-badge animated' : 'cart-badge'}>
                  <ShoppingCartOutlined style={{ fontSize: '24px' }} />
                </Badge>
              </Link>
            </Menu.Item>
            <Menu.Item key="language" className="language-selector">
  <Dropdown overlay={languageMenu} trigger={['click']}>
    <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <GlobalOutlined style={{ fontSize: '20px' }} /> {currentLanguage === 'en' ? t('EN') : t('LT')}
    </span>
  </Dropdown>
</Menu.Item>
          </Menu>
        </div>
      </Header>
    </Layout>
  );
};

export default Navbar;
