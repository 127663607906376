import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Button, Spin, message, InputNumber, Tooltip } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './SpiceBoxDetail.scss';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

interface Spice {
  _id: string;
  name: string;
  price: number;
  images: string[];
  description: string;
}

interface SpiceBox {
  _id: string;
  name: string;
  size: number;
  price: number;
  images: string[];
  description: string;
  spices: Spice[];
}

interface CartItem {
  _id: number;
  name: string;
  price: number;
  quantity: number;
  customization?: any[];
}

interface SpiceBoxDetailProps {
  cart: CartItem[];
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const SpiceBoxDetail: React.FC<SpiceBoxDetailProps> = ({ cart, setCart }) => {
  const { spiceBoxId } = useParams<{ spiceBoxId: string }>();
  const { t } = useTranslation();
  const [spiceBox, setSpiceBox] = useState<SpiceBox | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedSpices, setSelectedSpices] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    axios
      .get(`${API_URL}/api/spice-boxes/${spiceBoxId}`)
      .then((response) => {
        const spices = response.data.spices.reduce((acc: any, spice: Spice) => {
          acc[spice._id] = 0; // Initialize quantity to 0 for all spices
          return acc;
        }, {});
        setSelectedSpices(spices);
        setSpiceBox(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching the spice box!', error);
        setLoading(false);
      });
  }, [spiceBoxId]);

  const handleAddToCart = () => {
    if (!spiceBox) return;

    const totalSelected = Object.values(selectedSpices).reduce((sum, qty) => sum + qty, 0);

    if (totalSelected !== spiceBox.size) {
      message.error(
        `${t('pleaseSelectExactSpices')} (${spiceBox.size} ${t('total')}). ${t('current')} (${totalSelected})`
      );
      return;
    }

    const selectedSpicesDetails = Object.entries(selectedSpices)
      .filter(([_, qty]) => qty > 0)
      .map(([spiceId, qty]) => {
        const spice = spiceBox.spices.find((sp) => sp._id === spiceId);
        return {
          spiceId,
          spiceName: spice?.name || 'Unknown Spice',
          quantity: qty,
        };
      });

    // Check if an identical selection already exists in the cart
    const isDuplicate = cart.some(
      (item) =>
        item._id === spiceBox._id &&
        JSON.stringify(item.customization || []) === JSON.stringify(selectedSpicesDetails)
    );

    if (!isDuplicate) {
      setCart([
        ...cart,
        {
          _id: spiceBox._id,
          name: spiceBox.name,
          price: spiceBox.price,
          quantity: 1,
          customization: selectedSpicesDetails,
        },
      ]);
    } else {
      setCart(
        cart.map((item) =>
          item._id === spiceBox._id &&
          JSON.stringify(item.customization || []) === JSON.stringify(selectedSpicesDetails)
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    }

    message.success(`${spiceBox.name} ${t('addedToCart')} (x1)`);
  };

  const handleQuantityChange = (spiceId: string, quantity: number) => {
    const totalSelected = Object.values(selectedSpices).reduce(
      (sum, qty) => sum + qty - (spiceId in selectedSpices ? selectedSpices[spiceId] : 0),
      0
    );

    if (totalSelected + quantity > (spiceBox?.size || 0)) {
      message.error(`${t('exceedsSpiceBoxLimit')} (${spiceBox?.size})`);
      return;
    }

    setSelectedSpices((prev) => ({
      ...prev,
      [spiceId]: quantity,
    }));
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!spiceBox) {
    return <div>{t('spiceBoxNotFound')}</div>;
  }

  const allImages = [
    ...spiceBox.images
  ];

  const safeImages = allImages.filter((img) => img); // Remove null or undefined images


  const formatDescription = (description: string) => {
    return description.split('<br>').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="spice-box-detail">
      <h1>{spiceBox.name}</h1>

      <div className="spice-box-detail-content">
      <Carousel autoplay dots swipeToSlide className="spice-carousel">
  {safeImages.map((img, idx) => (
    <div key={idx} className="carousel-slide">
      <img
        src={img}
        alt={`${spiceBox.name} - ${idx}`}
        className="carousel-image"
      />
    </div>
  ))}
</Carousel>

        <div className="spice-box-info">

          <div className="product-description-container">
            <h3>{t('productDescription')}</h3>
            <p className="product-description"> {formatDescription(spiceBox.description)}</p>
          </div>
          <div className="size-section">
            <h3>{t('size')}</h3>
            <p>{t('spiceBoxSize', { size: spiceBox.size })}</p>
          </div>

          <div className="spices-section">
            <h3>{t('includedSpices')}</h3>
            <div className="spices-grid">
              {spiceBox.spices.map((spice) => (
                <div key={spice._id} className="spice-item">
<Tooltip
  title={
    <div className="spice-tooltip">
      <img
        src={spice.images[0] || 'https://via.placeholder.com/100'}
        alt={spice.name}
        width="100"
      />
      <h4>{spice.name}</h4>
      <p style={{ color: '#DBC078' }}>{spice.description.replace(/<br\s*\/?>/gi, '')}</p>
    </div>
  }
>
  <strong>{spice.name}</strong>
</Tooltip>
                  <InputNumber
                    min={0}
                    max={spiceBox.size}
                    value={selectedSpices[spice._id]}
                    onChange={(value) => handleQuantityChange(spice._id, value || 0)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="add-to-cart-section">
            <div className="price">€{spiceBox.price.toFixed(2)}</div>
            <Button type="primary" onClick={handleAddToCart}>
              {t('addToCart')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpiceBoxDetail;
