// ParcelShopForm.tsx

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  ListSubheader,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ParcelShopForm.scss';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const ParcelShopForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const cart = location.state?.cart || [];
  const totalAmount = location.state?.totalAmount || 0;
  const shippingMethod = location.state?.shippingMethod || '';

  const [details, setDetails] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    parcelShop: '',
    parcelLocation: '',
  });
  useEffect(() => {
    // Reset selectedCity and parcelLocation when parcelShop changes
    setSelectedCity('');
    setDetails((prevDetails) => ({ ...prevDetails, parcelLocation: '' }));
  
    if (details.parcelShop) {
      setLoading(true);
      axios
        .get(`${API_URL}/api/${details.parcelShop}/terminals`)
        .then((response) => {
          // Assign unique _id to terminals if not present
          const terminalsWithId = response.data.map((terminal: any, index: number) => ({
            ...terminal,
            _id: terminal._id || terminal.id || `terminal-${index}`,
          }));
          setTerminals(terminalsWithId);
          console.log(terminalsWithId);
          setLoading(false);
  
          const cities = Array.from(
            new Set(terminalsWithId.map((terminal: any) => terminal.city))
          );
          setAvailableCities(cities);
        })
        .catch((error) => {
          console.error(`Error fetching ${details.parcelShop} terminals:`, error);
          setLoading(false);
        });
    } else {
      setTerminals([]);
    }
  }, [details.parcelShop]);
  

  const [terminals, setTerminals] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [availableCities, setAvailableCities] = useState<string[]>([]);

  // Function to load terminals from the backend database
  const loadTerminalsFromBackend = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/api/lpexpress/terminals`);
      setTerminals(response.data);
      setLoading(false);

      const cities = Array.from(
        new Set(response.data.map((terminal: any) => terminal.city))
      );
      setAvailableCities(cities);
    } catch (error) {
      console.error('Error loading LP Express terminals:', error);
      setLoading(false);
    }
  };
  
  const handleParcelLocationChange = (event: SelectChangeEvent) => {
    const selectedParcelLocation = event.target.value;
  
    // Debugging logs to understand where the issue might be occurring
    console.log('Event target value:', event.target.value);
    console.log('Selected Parcel Location:', selectedParcelLocation);
  
    if (!selectedParcelLocation) {
      console.warn('Selected parcel location is undefined');
      return; // Exit the function if undefined to avoid further errors
    }
  
    setDetails((prevDetails) => ({
      ...prevDetails,
      parcelLocation: selectedParcelLocation,
    }));
  };
  


  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
    setDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleCityChange = (e: SelectChangeEvent) => {
    const city = e.target.value as string;
    setSelectedCity(city);
    // Reset parcelLocation when city changes
    setDetails((prevDetails) => ({ ...prevDetails, parcelLocation: '' }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!details.email.includes('@')) {
      alert(t('InvalidEmail'));
      return;
    }
  
    if (details.phoneNumber.length < 6) {
      alert(t('InvalidPhone'));
      return;
    }
  
    try {
      if (cart.length === 0) {
        console.error('Cart is empty');
        return;
      }
  
      const parcelLocation = terminals.find(
        (terminal) => terminal._id === details.parcelLocation
      );
  
      const updatedDetails = {
        ...details,
        address: parcelLocation?.address || details.address,
        city: parcelLocation?.city || details.city,
        country: 'Lithuania',
        postcode: '00000',
      };
  
      navigate('/checkout', {
        state: {
          cart,
          totalAmount,
          shippingMethod,
          details: updatedDetails,
          parcelLocation,
        },
      });
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };
  

  // Helper function to group terminals by the first letter of their names
  const getGroupedTerminals = (terminals: any[]) => {
    return terminals
      .filter((terminal) => terminal.city === selectedCity)
      .sort((a, b) => a.name.localeCompare(b.name))
      .reduce((groups: Record<string, any[]>, terminal) => {
        const letter = terminal.name[0].toUpperCase();
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(terminal);
        return groups;
      }, {});
  };

  const groupedTerminals = getGroupedTerminals(terminals);

  // Function to render MenuItems with unique keys
  const renderMenuItems = () => {
    return Object.keys(groupedTerminals)
      .sort()
      .reduce((acc: React.ReactNode[], letter) => {
        acc.push(
          <ListSubheader key={`header-${letter}`}>{letter}</ListSubheader>
        );
        groupedTerminals[letter].forEach((terminal: any, index: number) => {
          const terminalKey = `terminal-${terminal._id}`;
          acc.push(
            <MenuItem key={terminalKey} value={terminal._id}>
              {`${terminal.address} - ${terminal.name}`}
            </MenuItem>
          );
        });
        return acc;
      }, []);
  };
  
  
  
  

  return (
    <div className="details-form-page">
      <div className="details-form-container">
        <Typography variant="h4" gutterBottom className="form-heading">
          {t('EnterYourDetails')}
        </Typography>

        <form className="details-form" onSubmit={handleSubmit}>
          <TextField
            label={t('Name')}
            name="name"
            placeholder={t('Name')}
            value={details.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Surname')}
            name="surname"
            placeholder={t('Surname')}
            value={details.surname}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Email')}
            name="email"
            placeholder={t('Email')}
            value={details.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('PhoneNumber')}
            name="phoneNumber"
            placeholder={t('PhoneNumber')}
            value={details.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('ChooseParcelShop')}</InputLabel>
            <Select
              name="parcelShop"
              value={details.parcelShop}
              onChange={handleChange}
            >
              <MenuItem value="lpexpress">{t('LP Express')}</MenuItem>
            </Select>
          </FormControl>

          {details.parcelShop && (
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('ChooseCity')}</InputLabel>
              <Select value={selectedCity} onChange={handleCityChange}>
  {availableCities
    .sort((a, b) => a.localeCompare(b))
    .map((city: string) => (
      <MenuItem key={`city-${city}`} value={city}>
        {city}
      </MenuItem>
    ))}
</Select>

            </FormControl>
          )}

          {selectedCity && (
            <FormControl
              fullWidth
              margin="normal"
              disabled={loading || terminals.length === 0}
            >
              <InputLabel>{t('ChooseParcelLocation')}</InputLabel>
              <Select
  name="parcelLocation"
  value={details.parcelLocation}
  onChange={handleParcelLocationChange} // Use the specific handler
  disabled={loading}
  MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}
>
                {loading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  renderMenuItems()
                )}
              </Select>
            </FormControl>
          )}

          <Button
            type="submit"
            variant="contained"
            className="submit-btn"
            disabled={!details.parcelLocation}
          >
            {t('ProceedToPayment')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ParcelShopForm;
