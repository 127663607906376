import React from 'react';
import Cart from '../components/Cart.tsx';

interface CartPageProps {
  cart: { _id: number; name: string; price: number; quantity: number }[];
  setCart: React.Dispatch<React.SetStateAction<{ _id: number; name: string; price: number; quantity: number }[]>>;
  triggerDrizzleEffect: () => void;
}

const CartPage: React.FC<CartPageProps> = ({ cart, setCart, triggerDrizzleEffect }) => {

  const handleAddToCart = (id: number) => {
    const existingItem = cart.find(item => item._id === id);
    if (existingItem) {
      setCart(prevCart =>
        prevCart.map(item =>
          item._id === id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );

      // Trigger the drizzle effect
      triggerDrizzleEffect();
    }
  };

  return (
    <div className="container">
      <h1>Your Cart</h1>
      {/* Pass setCart to the Cart component */}
      <Cart cart={cart} setCart={setCart} />
      {/* Render the product list or other components where users can add more items to cart */}
    </div>
  );
};

export default CartPage;
