import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
} from '@mui/material';
import styles from './ReferrerTable.scss';

interface Props {
  data: Array<{ url: string; referrer: string; timestamp: string }>;
}

const ReferrerTable: React.FC<Props> = ({ data }) => {
  const [filterText, setFilterText] = useState(''); // For filtering
  const [page, setPage] = useState(0); // For pagination
  const rowsPerPage = 50; // Limit per page

  // Filter data by search text
  const filteredData = data.filter(
    (row) =>
      row.url.toLowerCase().includes(filterText.toLowerCase()) ||
      row.referrer.toLowerCase().includes(filterText.toLowerCase()) ||
      new Date(row.timestamp).toLocaleString().toLowerCase().includes(filterText.toLowerCase())
  );

  // Paginated data for the current page
  const paginatedData = filteredData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  // Handle page change
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle filter change
  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    setPage(0); // Reset to first page when filtering
  };

  return (
    <Box className={styles.tableContainer}>
      <Typography variant="h6">URL, Referrer, and Timestamp Analytics</Typography>

      {/* Search Box */}
      <TextField
        label="Filter by URL, Referrer, or Time"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filterText}
        onChange={handleFilterChange}
        placeholder="Search for URL, Referrer, or Time..."
      />

      {/* Table */}
      <TableContainer component={Paper} className={styles.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '40%' }}>URL</TableCell>
              <TableCell style={{ width: '30%' }}>Referrer</TableCell>
              <TableCell style={{ width: '30%' }}>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={styles.urlCell}>{row.url}</TableCell>
                <TableCell>{row.referrer}</TableCell>
                <TableCell>{new Date(row.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
            {paginatedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                  No results found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[50]} // Fixed rows per page
      />
    </Box>
  );
};

export default ReferrerTable;
