// FetchTerminals.tsx
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const FetchTerminals: React.FC = () => {
  const [terminals, setTerminals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchTerminals = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${API_URL}/api/terminals`);
      setTerminals(response.data);
    } catch (err) {
      setError('Failed to fetch terminals. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTerminals(); // Initial fetch on mount
  }, []);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
        Terminals
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box>
          {terminals.length > 0 ? (
            terminals.map((terminal, index) => (
              <Box key={index} sx={{ padding: 1, borderBottom: '1px solid #ddd' }}>
                <Typography variant="body1">{terminal.name}</Typography>
                <Typography variant="body2">City: {terminal.city}</Typography>
                <Typography variant="body2">Address: {terminal.address}</Typography>
              </Box>
            ))
          ) : (
            <Typography>No terminals available.</Typography>
          )}
        </Box>
      )}
      <Button variant="contained" onClick={fetchTerminals} sx={{ marginTop: 2 }}>
        Refresh Terminals
      </Button>
    </Box>
  );
};

export default FetchTerminals;
