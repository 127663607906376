import React from 'react';
import Checkout from '../components/Checkout.tsx';

const CheckoutPage: React.FC = () => {
  return (
    <div className="container">
      <Checkout />
    </div>
  );
};

export default CheckoutPage;
