import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Rules.scss';

const Rules: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="rules-container">
      <Typography variant="h4" className="rules-heading">
        {t('rulesTitle')}
      </Typography>

      <div className="rules-content">
        <Typography variant="h6" className="section-title">
          {t('generalProvisionsTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('generalProvisionsContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('purchaseAgreementTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('purchaseAgreementContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('pricesAndPaymentTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('pricesAndPaymentContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('deliveryTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('deliveryContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('returnsTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('returnsContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('expirationTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('expirationContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('buyerObligationsTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('buyerObligationsContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('dataProtectionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('dataProtectionContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('disputeResolutionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('disputeResolutionContent')}
        </Typography>
      </div>
    </div>
  );
};

export default Rules;
