import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ProductCard.scss';

interface ProductCardProps {
  _id: number;
  name: string;
  price?: number | null;
  images?: string[];
  image?: string;
  colour?: string;
  isDiscounted?: boolean;
  discountedPrice?: number | null;
  soldOut?: boolean; // Add the soldOut property
  onAddToCart: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  _id,
  name,
  price,
  images = [],
  image,
  colour = 'gold',
  isDiscounted = false,
  discountedPrice,
  soldOut = false, // Default soldOut to false
  onAddToCart,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (!soldOut) {
      navigate(`/product/${_id}`);
    }
  };

  const defaultImage = "https://i.ibb.co/0fC2XKJ/2024-09-10-22-22-03.jpg";
  const displayedImage = images && images.length > 0 ? images[0] : image || defaultImage;

  return (
    <Card
      className={`product-card ${soldOut ? 'sold-out' : ''} ${colour}`}
      hoverable={!soldOut}
      cover={<img alt={name} src={displayedImage} />}
      onClick={handleCardClick}
    >
      {soldOut && <div className="sold-out-overlay">{t('soldOut')}</div>}
      <div className="card-content">
        <Card.Meta
          title={name}
          description={
            isDiscounted && discountedPrice != null ? (
              <div className="price-container">
                <span className="discounted-price">€{discountedPrice.toFixed(2)}</span>
                <span className="original-price">€{price?.toFixed(2)}</span>
              </div>
            ) : (
              <span className="standard-price">€{price?.toFixed(2)}</span>
            )
          }
        />

        <Button
          type="primary"
          onClick={(e) => {
            e.stopPropagation();
            if (!soldOut) {
              onAddToCart();
            }
          }}
          className="add-to-cart"
          disabled={soldOut} // Disable the button if sold out
        >
          {soldOut ? t('soldOut') : t('addToCart')}
        </Button>
      </div>
    </Card>
  );
};

export default ProductCard;
