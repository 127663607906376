import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductList from '../components/ProductList.tsx';
import Cart from '../components/Cart.tsx';
import { useTranslation } from 'react-i18next';
import Banner from '../components/Banner.tsx';
import LocalImage3 from '../components/images/banner.png'; // Import your local image
import { Helmet } from 'react-helmet'; // Import Helmet for managing meta tags

interface Product {
  _id: number;
  name: string;
  price: number;
  image: string;
  images: string[];
  colour: string;
  description: string;
  discountedPrice?: number;
  isDiscounted?: boolean;
  nutrition?: {
    servingSize: string;
    calories: number;
    totalFat: string;
    totalFatPercent: string;
    saturatedFat: string;
    saturatedFatPercent: string;
    cholesterol: string;
    cholesterolPercent: string;
    sodium: string;
    sodiumPercent: string;
    totalCarbohydrate: string;
    totalCarbohydratePercent: string;
    protein: string;
    proteinPercent: string;
  };
}

interface CartItem {
  _id: number;
  name: string;
  price: number;
  quantity: number;
  images?: string[];
  image?: string;
  isDiscounted?: boolean;
  discountedPrice?: number;
}

interface HomeProps {
  cart: CartItem[];
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
  triggerDrizzleEffect: () => void;
}

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const Home: React.FC<HomeProps> = ({ cart, setCart, triggerDrizzleEffect }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[]>([]);
  const [spiceBoxes, setSpiceBoxes] = useState<Product[]>([]);

  // Fetch products
  useEffect(() => {
    axios
      .get(`${API_URL}/api/products`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the products!', error);
      });
  }, []);

  // Fetch spice boxes
  useEffect(() => {
    axios
      .get(`${API_URL}/api/spice-boxes`)
      .then((response) => {
        setSpiceBoxes(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching the spice boxes!', error);
      });
  }, []);

  const handleAddToCart = (id: number) => {
    const product = [...products, ...spiceBoxes].find((product) => product._id === id);
    if (product) {
      // Determine the price to add based on discount
      const priceToAdd =
        product.isDiscounted && product.discountedPrice != null
          ? product.discountedPrice
          : product.price;

      setCart((prevCart) => {
        const existingItem = prevCart.find((item) => item._id === product._id);
        if (existingItem) {
          // Update quantity if the item already exists
          return prevCart.map((item) =>
            item._id === product._id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          // Add new item with the correct price and discount properties
          const cartItem: CartItem = {
            _id: product._id,
            name: product.name,
            price: priceToAdd,
            quantity: 1,
            images: product.images,
            image: product.image,
            isDiscounted: product.isDiscounted,
            discountedPrice: product.discountedPrice,
          };
          return [...prevCart, cartItem];
        }
      });

      // Trigger the drizzle effect when the item is added to the cart
      triggerDrizzleEffect();
    }
  };

  return (
    <div style={{ marginTop: '60px' }}>
      <Helmet>
        <title>Popcornas - Premium Quality Popcorn Seasoning</title>
        <meta
          name="description"
          content="Experience the best in popcorn seasoning with Popcornas. We offer top-notch, gourmet seasonings that will elevate your popcorn to a whole new level. Taste the quality!"
        />
        <meta
          name="keywords"
          content="popcorn seasoning, premium popcorn, gourmet popcorn seasoning, best popcorn flavors, quality popcorn seasoning, flavored popcorn"
        />
        <meta property="og:title" content="Popcornas - Premium Quality Popcorn Seasoning" />
        <meta
          property="og:description"
          content="Transform your popcorn experience with Popcornas' gourmet seasonings. High-quality ingredients, unique flavors, and exceptional taste await you."
        />
        <meta property="og:image" content={LocalImage3} />
        <meta property="og:url" content="https://popcornas.lt" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Popcornas" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Popcornas - Premium Quality Popcorn Seasoning"
        />
        <meta
          name="twitter:description"
          content="Discover the finest popcorn seasonings at Popcornas. Premium quality, unique flavors, and perfect taste for your popcorn."
        />
        <meta name="twitter:image" content={LocalImage3} />

        {/* Links to Your Social Media for SEO and Branding */}
        <link
          rel="me"
          href="https://www.facebook.com/profile.php?id=61561916528887"
        />
        <link rel="me" href="https://www.instagram.com/mr.popcornas/" />
        <link
          rel="me"
          href="https://www.etsy.com/shop/MrPopcornas?ref=seller-platform-mcnav"
        />
      </Helmet>

      <Banner image={LocalImage3} />

      <h1 style={{ marginTop: '30px' }}></h1>
      <ProductList
        spiceBoxes={spiceBoxes} // Pass spice boxes to ProductList
        products={products} // Pass products to ProductList
        onAddToCart={handleAddToCart}
      />
    </div>
  );
};

export default Home;
