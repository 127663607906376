import React from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import styles from './PieChartComponent.module.scss';

interface Props {
  title: string;
  data: Array<{ name: string; value: number }>;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF5757', '#82ca9d'];

const PieChartComponent: React.FC<Props> = ({ title, data }) => (
    <Box className={styles.chartWrapper}>
      <Typography variant="h6">{title}</Typography>
      {data.length > 1 ? (
        <ResponsiveContainer className={styles.chart}>
          <PieChart>
            <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={150} label>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <Typography variant="body1">Not enough data to display chart.</Typography>
      )}
    </Box>
  );
  

export default PieChartComponent;
