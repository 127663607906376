// PrivacyPolicy.tsx
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <Typography variant="h4" className="privacy-policy-heading">
        {t('privacyPolicyTitle')}
      </Typography>
      
      {/* Existing Privacy Policy Sections */}
      <div className="privacy-policy-content">
      <Typography variant="h6" className="section-title">
          {t('introductionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('introductionContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section1Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section1Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section2Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section2Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section3Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section3Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section4Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section4Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section5Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section5Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section6Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section6Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section7Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section7Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section8Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section8Content')}
        </Typography>
      </div>

      {/* New Privacy Policy Sections */}
      <div className="privacy-policy-content">

        <Typography variant="h6" className="section-title">
          {t('legalBasisTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('legalBasisContent')}
        </Typography>
        <Typography variant="h6" className="section-title">
          {t('contactTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('contactContent')}
        </Typography>
      </div>
      <div className="privacy-policy-content">

      </div>
    </div>
  );
};

export default PrivacyPolicy;
