import React, { useState } from 'react';
import { Drawer, Button, Menu, Dropdown, Badge } from 'antd';
import { ShoppingCartOutlined, GlobalOutlined, MenuOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './MobileNavbar.scss';

interface MobileNavbarProps {
  cartCount: number;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({ cartCount }) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<'en' | 'lt'>('lt'); // Default to Lithuanian

  const changeLanguage = (lng: 'en' | 'lt') => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  const languageMenu = (
    <Menu>
      <Menu.Item key="en" onClick={() => changeLanguage('en')}>
        {t('EN')}
      </Menu.Item>
      <Menu.Item key="lt" onClick={() => changeLanguage('lt')}>
        {t('LT')}
      </Menu.Item>
    </Menu>
  );

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <div className="mobile-navbar">
      {/* Top Bar with Menu and Logo */}
      <div className="mobile-navbar-header">
        <Button
          type="text"
          icon={<MenuOutlined />}
          onClick={showDrawer}
          className="mobile-menu-button"
        />
        <Link to="/" className="mobile-logo">
          <img
            src="https://i.ibb.co/mqktPFn/Main-Logo-black.png"
            alt="Logo"
            className="mobile-logo-img"
          />
        </Link>
        <Link to="/cart" className="cart-icon-link">
          <Badge count={cartCount} className="cart-badge">
            <ShoppingCartOutlined style={{ fontSize: '24px', color: '#fff' }} />
          </Badge>
        </Link>
      </div>

      {/* Drawer for the menu */}
      <Drawer
        title={t('Menu')}
        placement="left"
        onClose={onClose}
        visible={visible}
        className="mobile-drawer"
      >
        <Menu theme="light" mode="vertical" selectable={false}>
          <Menu.Item key="products">
            <Link to="/" onClick={onClose}>
              {t('Products')}
            </Link>
          </Menu.Item>
          <Menu.Item key="about">
            <Link to="/about" onClick={onClose}>
              {t('About Us')}
            </Link>
          </Menu.Item>
          <Menu.Item key="contact">
            <Link to="/contact" onClick={onClose}>
              {t('Contacts')}
            </Link>
          </Menu.Item>
          <Menu.Item key="cart">
            <Link to="/cart" onClick={onClose}>
              <ShoppingCartOutlined /> {t('Cart')} ({cartCount})
            </Link>
          </Menu.Item>
          <Menu.Item key="language" className="language-selector">
  <Dropdown overlay={languageMenu} trigger={['click']}>
    <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <GlobalOutlined style={{ fontSize: '20px' }} /> {currentLanguage === 'en' ? t('EN') : t('LT')}
    </span>
  </Dropdown>
</Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );
};

export default MobileNavbar;
