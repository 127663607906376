import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import './ContactUs.scss'; // Import the styling
import { useTranslation } from 'react-i18next'; // i18n for translation

// Use the defined BASE_URL for backend requests
const BASE_URL = 'http://localhost:5001';
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const ContactUs: React.FC = () => {
  const { t } = useTranslation(); // Initialize translation
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Make a POST request to the backend API
      const response = await axios.post(`${API_URL}/api/contact`, formData);

      if (response.status === 201) {
        setSuccessMessage(t('messageSent'));
        setFormData({ name: '', email: '', message: '' }); // Clear the form
      } else {
        setErrorMessage(t('sendErrorMessage'));
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setErrorMessage(t('errorOccurredMessage'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-us">
      <div className="contact-intro">
        <Typography variant="h4" gutterBottom className="intro-heading">
          {t('getInTouch')}
        </Typography>
        <Typography variant="body1" className="intro-subtext">
          {t('contactMessage')}
        </Typography>
      </div>

      <div className="contact-info">
        <div className="info-box">
          <Typography variant="h6" className="info-title">{t('email')}</Typography>
          <Typography variant="body1" className="info-detail">sales@popcornas.lt</Typography>
        </div>
        <div className="info-box">
          <Typography variant="h6" className="info-title">{t('phone')}</Typography>
          <Typography variant="body1" className="info-detail">+37069342448</Typography>
        </div>
        <div className="info-box">
          <Typography variant="h6" className="info-title">{t('address')}</Typography>
          <Typography variant="body1" className="info-detail">Didlaukio g. 39 Vilnius</Typography>
        </div>
        <div className="info-box">
          <Typography variant="h6" className="info-title">{t('CompanyInfo')}</Typography>
          <Typography variant="body1" className="info-detail">Mr popcornas, MB (306946658)</Typography>
        </div>
      </div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <TextField
            label={t('name')}
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            placeholder={t('namePlaceholder')}
            InputProps={{
              className: 'contact-input',
            }}
          />
          <TextField
            label={t('email')}
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            placeholder={t('emailPlaceholder')}
            type="email"
            InputProps={{
              className: 'contact-input',
            }}
          />
          <TextField
            label={t('message')}
            name="message"
            value={formData.message}
            onChange={handleChange}
            fullWidth
            required
            margin="normal"
            multiline
            rows={4}
            placeholder={t('messagePlaceholder')}
            InputProps={{
              className: 'contact-input',
            }}
          />
          {successMessage && (
            <Typography variant="body1" style={{ color: 'green', marginTop: '10px' }}>
              {successMessage}
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="body1" style={{ color: 'red', marginTop: '10px' }}>
              {errorMessage}
            </Typography>
          )}
          <Button type="submit" variant="contained" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? t('sending') : t('sendMessage')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
