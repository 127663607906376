import React from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from './LineChartComponent.module.scss';

interface Props {
  data: Array<{ date: string; analytics: number; fingerprints: number }>;
}

const LineChartComponent: React.FC<Props> = ({ data }) => (
  <Box className={styles.chartWrapper}>
    <Typography variant="h6">Web & Fingerprint Analytics Over Time</Typography>
    <ResponsiveContainer width="100%" height={400}>
  <LineChart data={data}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="analytics" stroke="#8884d8" name="Page Views" />
    <Line type="monotone" dataKey="fingerprints" stroke="#82ca9d" name="Fingerprint Data" />
  </LineChart>
</ResponsiveContainer>

  </Box>
);

export default LineChartComponent;
