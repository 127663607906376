import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './SpiceCard.scss';

interface SpiceCardProps {
  _id: number;
  name: string;
  size: number; // Size of the spice box
  price: number;
  images: string[];
  description: string;
  shortDescription: string;
  onAddToCart: () => void;
}

const SpiceCard: React.FC<SpiceCardProps> = ({
  _id,
  name,
  size,
  price,
  images,
  description,
  shortDescription
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/spice-box/${_id}`);
  };

  const defaultImage = "https://i.ibb.co/0fC2XKJ/2024-09-10-22-22-03.jpg";
  const displayedImage = images.length > 0 ? images[0] : defaultImage;
  console.log('Translated size:', t('spiceBoxSize', { size }));
  return (
    <Card
      className="spice-card"
      hoverable
      cover={<img alt={name} src={displayedImage} />}
      onClick={handleCardClick}
    >
      <div className="card-content">
        <Card.Meta 
          title={name} 
          description={
            <div>
              <p className="spice-box-size">{t('spiceBoxSize', { size })}</p>
              <p className="spice-box-price">€{price.toFixed(2)}</p>
              <p className="spice-box-description">{shortDescription}</p>
            </div>
          }
        />

        <Button
          type="primary"
          onClick={handleCardClick}

          className="add-to-cart"
        >
          {t('selectSpices')}
        </Button>
      </div>
    </Card>
  );
};

export default SpiceCard;
