import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Tabs, Tab } from '@mui/material';
import OrdersManagement from './OrdersManagement.tsx';
import AddProduct from './AddProduct.tsx';
import EditProduct from './EditProduct.tsx';
import ContactMessages from './ContactMessages.tsx';
import CombinedAnalytics from './CombinedAnalytics.tsx';
import FetchTerminals from './FetchTerminals.tsx';
import SpiceBoxManagement from './SpiceBoxManagement.tsx';
import CryptoJS from 'crypto-js';

const AdminPage: React.FC = () => {
  // State to handle login
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  // State to handle tabs
  const [tabIndex, setTabIndex] = useState(0);

  // Load hashed password from environment variable or secure storage
  const ADMIN_PASSWORD_HASH = process.env.REACT_APP_ADMIN_PASSWORD_HASH || '';

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault(); // Prevent page reload on form submission

    // Hash the entered password using the same hashing method
    const hashedPassword = CryptoJS.SHA256(password).toString();

    if (hashedPassword === ADMIN_PASSWORD_HASH) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ padding: 4, paddingTop: 12.5 }}>
      {!isAuthenticated ? (
        <Box sx={{ textAlign: 'center', marginTop: 4 }}>
          <Typography variant="h5" component="div" sx={{ marginBottom: 2 }}>
            Admin Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Enter Password"
              type="password"
              name="password" // This enables browser saving/autofill
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              sx={{ maxWidth: 300, margin: '0 auto 16px auto' }}
            />
            <Button type="submit" variant="contained">
              Login
            </Button>
          </form>
        </Box>
      ) : (
        <>
          <Typography variant="h4" component="div" sx={{ textAlign: 'center', margin: 2 }}>
            Admin Panel
          </Typography>

          {/* Tabs */}
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Orders Management" />
            <Tab label="Add Product" />
            <Tab label="Edit Product" />
            <Tab label="Contact Messages" />
            <Tab label="Combined Analytics" />
            <Tab label="Fetch terminals" />
            <Tab label="Spice Boxes" />

            {/* Add more tabs as needed */}
          </Tabs>

          {/* Tab Content */}
          {tabIndex === 0 && <OrdersManagement />}
          {tabIndex === 1 && <AddProduct />}
          {tabIndex === 2 && <EditProduct />}
          {tabIndex === 3 && <ContactMessages />}
          {tabIndex === 4 && <CombinedAnalytics />}
          {tabIndex === 5 && <FetchTerminals />}
          {tabIndex === 6 && <SpiceBoxManagement />}

          {/* Add more tab contents as needed */}
        </>
      )}
    </Box>
  );
};

export default AdminPage;
