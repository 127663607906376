import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the i18n hook
import './Footer.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="left">
          <Link to="/privacy-policy" className="footer-link">
            {t('privacy_policy')} {/* Translatable Privacy Policy */}
          </Link>
        </div>
        <div className="divider"></div>
        <div className="right">
          <Link to="/rules" className="footer-link">
            {t('rules')} {/* Translatable Rules */}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
