export const filterDataByTimeRange = (data, range) => {
  const now = Date.now(); // Current UTC time
  const ranges = {
    '1h': 1 * 60 * 60 * 1000, // 1 hour in ms
    '6h': 6 * 60 * 60 * 1000, // 6 hours in ms
    '24h': 24 * 60 * 60 * 1000, // 24 hours in ms
    '5d': 5 * 24 * 60 * 60 * 1000, // 5 days in ms
    '30d': 30 * 24 * 60 * 60 * 1000, // 30 days in ms
  };
  const rangeInMs = ranges[range];
  if (!rangeInMs) {
    console.error('Invalid time range selected:', range);
    return [];
  }

  return data.filter(entry => {
    const entryTime = new Date(entry.timestamp).getTime();
    if (isNaN(entryTime)) {
      console.warn('Invalid timestamp detected:', entry.timestamp);
      return false; // Exclude invalid timestamps
    }
    return entryTime >= now - rangeInMs;
  });
};


export const prepareChartData = (analyticsData, fingerprintData, timeRange) => {
  const dataByDate = {};

  // Determine grouping granularity based on time range
  const getGroupingFormat = (timeRange) => {
    if (timeRange === '1h' || timeRange === '6h') {
      // Group by minute
      return 'minute';
    } else if (timeRange === '24h' || timeRange === '5d') {
      // Group by hour
      return 'hour';
    } else if (timeRange === '30d') {
      // Group by day
      return 'day';
    } else {
      // Default to hour if time range is unrecognized
      return 'hour';
    }
  };

  const grouping = getGroupingFormat(timeRange);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    if (grouping === 'minute') {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')} ${date
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:${date
        .getUTCMinutes()
        .toString()
        .padStart(2, '0')}`;
    } else if (grouping === 'hour') {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')} ${date
        .getUTCHours()
        .toString()
        .padStart(2, '0')}:00`;
    } else if (grouping === 'day') {
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
    }
  };

  analyticsData.forEach((entry) => {
    const date = formatDate(entry.timestamp);
    if (!dataByDate[date]) dataByDate[date] = { analytics: 0, fingerprints: 0 };
    dataByDate[date].analytics += 1;
  });

  fingerprintData.forEach((entry) => {
    const date = formatDate(entry.timestamp);
    if (!dataByDate[date]) dataByDate[date] = { analytics: 0, fingerprints: 0 };
    dataByDate[date].fingerprints += 1;
  });

  // Sort the data by date
  const sortedData = Object.entries(dataByDate)
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, counts]) => ({
      date,
      analytics: counts.analytics,
      fingerprints: counts.fingerprints,
    }));

  return sortedData;
};



export const prepareDeviceDistributionData = (fingerprintData) => {
  const deviceCounts = {};
  fingerprintData.forEach(entry => {
    const device = entry.device || 'Unknown';
    deviceCounts[device] = (deviceCounts[device] || 0) + 1;
  });
  return Object.entries(deviceCounts).map(([device, count]) => ({
    name: device,
    value: count,
  }));
};

export const prepareScreenResolutionData = (fingerprintData) => {
  const resolutionCounts = {};
  fingerprintData.forEach(entry => {
    const resolution = entry.screenResolution || 'Unknown';
    resolutionCounts[resolution] = (resolutionCounts[resolution] || 0) + 1;
  });
  return Object.entries(resolutionCounts).map(([resolution, count]) => ({
    name: resolution,
    value: count,
  }));
};

export const prepareLanguageData = (fingerprintData) => {
  const languageCounts = {};
  fingerprintData.forEach(entry => {
    const language = entry.language || 'Unknown';
    languageCounts[language] = (languageCounts[language] || 0) + 1;
  });
  return Object.entries(languageCounts).map(([language, count]) => ({
    name: language,
    value: count,
  }));
};

export const prepareOSVersionData = (fingerprintData) => {
  const osVersionCounts = {};
  fingerprintData.forEach(entry => {
    const osVersion = entry.osVersion || 'Unknown';
    osVersionCounts[osVersion] = (osVersionCounts[osVersion] || 0) + 1;
  });
  return Object.entries(osVersionCounts).map(([osVersion, count]) => ({
    name: osVersion,
    value: count,
  }));
};
