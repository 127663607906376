import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar.tsx';
import Footer from './components/Footer.tsx';
import MobileNavbar from './components/MobileNavbar.tsx'; // Import the MobileNavbar component
import Home from './pages/Home.tsx';
import CartPage from './pages/CartPage.tsx';
import Cart from './components/Cart.tsx';
import CheckoutPage from './pages/CheckoutPage.tsx';
import ProductList from './components/ProductList.tsx';
import ProductDetail from './components/ProductDetail.tsx';
import AdminPage from './pages/AdminPage.tsx';
import AboutUs from './components/AboutUs.tsx';
import DetailsForm from './components/DetailsForm.tsx';
import ContactUs from './components/ContactUs.tsx';
import PrivacyPolicy from './components/PrivacyPolicy.tsx';
import ParcelShopForm from './components/ParcelShopForm.tsx';
import './i18n.ts'; // Import the i18n configuration
import './App.css';
import PaymentSuccessPage from './components/PaymentSuccessPage.tsx';
import PaymentCancelPage from './components/PaymentCancelPage.tsx';
import Rules from './components/Rules.tsx';
import SpiceBoxDetail from './components/SpiceBoxDetail.tsx';

const App: React.FC = () => {
  const [cart, setCart] = useState<{ _id: number; name: string; price: number; quantity: number }[]>([]);

  const clearCart = () => {
    setCart([]);
  };

  // Calculate the total number of items in the cart
  const cartCount = cart.reduce((acc, item) => acc + item.quantity, 0);

  // State to track whether we're on a mobile device
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  // Update the isMobile state when the window is resized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    // Load cart from localStorage
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }

    // Add resize event listener
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Save cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const triggerDrizzleEffect = () => {
    console.log("Drizzle effect triggered!");
    for (let i = 0; i < 5; i++) {
      const drizzleElement = document.createElement('div');
      drizzleElement.className = 'drizzle-effect';
      document.body.appendChild(drizzleElement);

      const sparklesElement = document.createElement('div');
      sparklesElement.className = 'drizzle-sparkles';
      document.body.appendChild(sparklesElement);

      setTimeout(() => {
        document.body.removeChild(drizzleElement);
        document.body.removeChild(sparklesElement);
      }, 1000); // Remove after 1 second
    }
  };

  const products = [
    {
      id: '1',
      name: 'Cheese',
      price: 5.99,
      image: 'https://example.com/cheese.jpg',
      images: [
        'https://example.com/cheese1.jpg',
        'https://example.com/cheese2.jpg',
      ],
      colour: 'cheese',
      description: 'A delicious cheese product.',
      nutrition: {
        servingSize: '4 oz (112g)',
        calories: 150,
        totalFat: '6g',
        totalFatPercent: '9%',
        saturatedFat: '2.5g',
        saturatedFatPercent: '13%',
        cholesterol: '70mg',
        cholesterolPercent: '23%',
        sodium: '75mg',
        sodiumPercent: '3%',
        totalCarbohydrate: '0g',
        totalCarbohydratePercent: '0%',
        protein: '24g',
        proteinPercent: '48%',
      },
      addToCart: () => alert('Cheese added to cart'),
    },
    {
      id: '2',
      name: 'Bacon',
      price: 8.99,
      image: 'https://example.com/bacon.jpg',
      images: [
        'https://example.com/bacon1.jpg',
        'https://example.com/bacon2.jpg',
      ],
      colour: 'bacon',
      description: 'Crispy and delicious bacon.',
      nutrition: {
        servingSize: '2 oz (56g)',
        calories: 200,
        totalFat: '16g',
        totalFatPercent: '25%',
        saturatedFat: '6g',
        saturatedFatPercent: '30%',
        cholesterol: '40mg',
        cholesterolPercent: '13%',
        sodium: '300mg',
        sodiumPercent: '13%',
        totalCarbohydrate: '1g',
        totalCarbohydratePercent: '0%',
        protein: '10g',
        proteinPercent: '20%',
      },
      addToCart: () => alert('Bacon added to cart'),
    },
    {
      id: '3',
      name: 'Water',
      price: 1.00,
      image: 'https://example.com/water.jpg',
      images: [
        'https://example.com/water1.jpg',
        'https://example.com/water2.jpg',
      ],
      colour: 'water',
      description: 'Refreshing water.',
      addToCart: () => alert('Water added to cart'),
    },
  ];

  return (
    <Router>
      {/* Render Navbar based on screen size */}
      {isMobile ? <MobileNavbar cartCount={cartCount} /> : <Navbar cartCount={cartCount} />}
      <div className="app-container">
        <div className="main-content">
      <Routes>
        <Route path="/" element={<Home cart={cart} setCart={setCart} triggerDrizzleEffect={triggerDrizzleEffect} />} />
        <Route path="/cart" element={<CartPage cart={cart} setCart={setCart} triggerDrizzleEffect={triggerDrizzleEffect} />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/cart" element={<Cart cart={cart} setCart={setCart} />} />
        <Route path="/details" element={<DetailsForm />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/products" element={<ProductList products={products} />} />
        <Route path="/product/:productId" element={<ProductDetail cart={cart} setCart={setCart} />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/parcel-shop" element={<ParcelShopForm />} />
        <Route path="/payment-success" element={<PaymentSuccessPage clearCart={clearCart}/>} />
        <Route path="/payment-cancel" element={<PaymentCancelPage />} />
        <Route path="/spice-box/:spiceBoxId" element={<SpiceBoxDetail cart={cart} setCart={setCart} />} />
      </Routes>
      </div>
      <Footer />
      </div>
    </Router>
  );
};

export default App;
