import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import styles from './DataTable.module.scss';

interface Props {
  data: Array<{ date: string; analytics: number; fingerprints: number }>;
  
}

const DataTable: React.FC<Props> = ({ data }) => (
  <Paper className={styles.tableWrapper}>
    <Typography variant="h6">Combined Analytics Data</Typography>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Page Views</TableCell>
            <TableCell>Fingerprint Entries</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.analytics}</TableCell>
              <TableCell>{row.fingerprints}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
);

export default DataTable;
