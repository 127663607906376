import React from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.scss';
import { FaFacebook, FaInstagram, FaEtsy, FaAmazon } from 'react-icons/fa';
import LocalImage from '../components/images/1.png'; // Import your local image
import LocalImage2 from '../components/images/2.png'; // Import your local image
import LocalImage3 from '../components/images/3.png'; // Import your local image

const AboutUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="about-us">
      {/* Introduction Section */}
      <div className="about-intro">
        <h1>{t('welcome')}</h1>
        <p>{t('discover')}</p>
      </div>

      {/* About Cards Section */}
      <div className="about-cards">
        <div className="about-card">
        <img src={LocalImage} alt={t('historyTitle')} />
          <div className="card-content">
            <h2>{t('historyTitle')}</h2>
            <p>{t('historyDescription')}</p>
          </div>
        </div>

        <div className="about-card">
        <img src={LocalImage2} alt={t('historyTitle')} />
          <div className="card-content">
            <h2>{t('processTitle')}</h2>
            <p>{t('processDescription')}</p>
          </div>
        </div>

        <div className="about-card">
        <img src={LocalImage3} alt={t('historyTitle')} />
          <div className="card-content">
            <h2>{t('missionTitle')}</h2>
            <p>{t('missionDescription')}</p>
          </div>
        </div>
      </div>

      {/* Dots Timeline Section */}
      <div className="timeline-section">
        <h2>{t('story')}</h2>
        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>{t('timeline2023June')}</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>{t('timeline2023Dec')}</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>{t('timeline2024April')}</h3>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content">
              <h3>{t('timeline2024')}</h3>
            </div>
          </div>
        </div>
      </div>

      {/* Social Media Links Section */}
      <div className="social-links">
        <h2>{t('followUs')}</h2>
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61561916528887" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/mr.popcornas?igsh=MTY1NnByMXQ0c21jOQ==" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a>
          <a href="https://www.etsy.com/shop/MrPopcornas?ref=seller-platform-mcnav" target="_blank" rel="noopener noreferrer">
            <FaEtsy />
          </a>
          {/* <a href="https://amazon.com" target="_blank" rel="noopener noreferrer">
            <FaAmazon />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
