import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios'; // For sending data to the backend
import './DetailsForm.scss';

const DetailsForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const cart = location.state?.cart || []; // Retrieve cart from location.state
  const totalAmount = location.state?.totalAmount || 0;
  const shippingMethod = location.state?.shippingMethod || '';

  const [details, setDetails] = useState({
    name: '',
    surname: '',
    email: '',
    phoneNumber: '',
    city: '',
    address: '',
    postcode: '',
    country: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    try {
      // Ensure cart is not empty
      if (cart.length === 0) {
        console.error('Cart is empty');
        return;
      }

      // Navigate to the checkout page with the collected details
      navigate('/checkout', {
        state: {
          cart,
          totalAmount,
          shippingMethod,
          details, // Pass the details entered by the user
        }
      });
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };
  
  return (
    <div className="details-form-page">
      <div className="details-form-container">
        <Typography variant="h4" gutterBottom className="form-heading">
          {t('EnterYourDetails')}
        </Typography>

        <form className="details-form" onSubmit={handleSubmit}>
          <TextField
            label={t('Name')}
            name="name"
            placeholder={t('Name')}
            value={details.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Surname')}
            name="surname"
            placeholder={t('Surname')}
            value={details.surname}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Email')}
            name="email"
            placeholder={t('Email')}
            value={details.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('PhoneNumber')}
            name="phoneNumber"
            placeholder={t('PhoneNumber')}
            value={details.phoneNumber}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('City')}
            name="city"
            placeholder={t('City')}
            value={details.city}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Address')}
            name="address"
            placeholder={t('Address')}
            value={details.address}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Postcode')}
            name="postcode"
            placeholder={t('Postcode')}
            value={details.postcode}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t('Country')}
            name="country"
            placeholder={t('Country')}
            value={details.country}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          <Button type="submit" variant="contained" className="submit-btn">
            {t('ProceedToPayment')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default DetailsForm;
